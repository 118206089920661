
import { defineComponent, ref, onMounted, watch, reactive, toRef } from 'vue';
import { useRoute } from 'vue-router';
import Container from '@/components/Container.vue';
import Pagination from '@/components/Pagination/index.vue';
import router from '@/router/index';
import ListFilter from './ListFilter.vue';
import ListTable from './ListTable.vue';
import partnerAPI from '@/service/partnerAPI';
import format from 'date-fns/format/index.js';
import datePattern from '@/constants/datePattern';

export type ProductListParams = {
  partnerId?: number
  start?: string;
  end?: string;
  categoryDepth1Id?: number;
  salesStatus?: any;
  enableBid?: boolean;
  keyword?: string;
  page: number;
  pageSize: number;
  showInvisibled?: boolean;
  showDeleted?: boolean;
  sortBy?: string
};

export default defineComponent({
  name: 'Product',
  components: {
    Container,
    ListFilter,
    ListTable,
    Pagination,
  },
  props: {},
  setup() {
    const route = useRoute();
    const query = route.query;

    const filterParams = reactive<ProductListParams>({
      partnerId: Number(query.partnerId) || undefined,
      start: (query.start as string) || '',
      end: (query.end as string) || '',
      categoryDepth1Id: Number(query.categoryDepth1Id) || undefined,
      salesStatus: (query.salesStatus as string) || '',
      enableBid: query.enableBid === 'true' ? true : query.enableBid === 'false' ? false : undefined,
      keyword: (query.keyword as string) || '',
      page: Number(query.page) || 0,
      pageSize: Number(query.pageSize) || 10,
      showDeleted: query.showDeleted === 'true' ? true : query.showDeleted === 'false' ? false : undefined,
      showInvisibled: query.showInvisibled === 'true' ? true : query.showInvisibled === 'false' ? false : undefined,
      sortBy: (query.sortBy as string) || undefined
    });

    const isFilterOn = ref(query.isFilter === 'true');

    const productList = ref({
      success: true,
      data: [],
      message: '',
      total: 0,
    });

    const toggleApplyFilters = () => {
      isFilterOn.value = !isFilterOn.value;
      executeSearch();
    };

    const handleChangeFilterParams = (key: string, value: any) => {
      console.log('key, value', key, value);
      filterParams[key] = value;
    };

    const handleChangePage = (page: number) => {
      filterParams.page = page - 1;
      executeSearch();
    };
    const handleChangePageSize = (pageSize: number) => {
      filterParams.page = 0;
      filterParams.pageSize = pageSize;
      executeSearch();
    };

    const searchPartner = ({ partnerId }) => {
      console.log('서치파트너', partnerId)
      filterParams.partnerId = partnerId
      filterParams.page = 0
      executeSearch()
    }

    const removeSearchPartner = () => {
      filterParams.partnerId = undefined
      filterParams.page = 0
      executeSearch()
    }

    const handleChangeSort = (sortBy) => {
      filterParams.sortBy = sortBy
      executeSearch()
    }

    /**
     * 검색 실행
     */
    const executeSearch = () => {
      // fetchProductLists();
      router.push({
        path: window.location.pathname,
        query: {
          partnerId: filterParams.partnerId || '',
          start: filterParams.start || '',
          end: filterParams.end || '',
          categoryDepth1Id: filterParams.categoryDepth1Id || '',
          salesStatus: filterParams.salesStatus || '',
          enableBid: String(filterParams.enableBid) || '',
          keyword: filterParams.keyword || '',
          page: filterParams.page || 0,
          pageSize: filterParams.pageSize || 10,
          isFilter: String(isFilterOn.value),
          showInvisibled: String(filterParams.showInvisibled) || '',
          showDeleted: String(filterParams.showDeleted) || '',
          sortBy: String(filterParams.sortBy) || ''
        },
      });
    };

    const getAppliedFilterParams = () =>
      isFilterOn.value
        ? {
            ...filterParams,
            start: filterParams.start
              ? format(
                  new Date(filterParams.start),
                  datePattern.INPUT_DATE + ' 00:00:00'
                )
              : undefined,
            end: filterParams.end
              ? format(
                  new Date(filterParams.end),
                  datePattern.INPUT_DATE + ' 23:59:59'
                )
              : undefined,
          }
        : {
            // 필터가 적용되어있지 않으면 키워드와 파트너아이디, 정렬만 사용한다
            partnerId: filterParams.partnerId,
            sortBy: filterParams.sortBy,
            keyword: filterParams.keyword,
            page: filterParams.page,
            pageSize: filterParams.pageSize,
          };

    const fetchProductLists = async () => {
      console.group('fetchProductLists');
      console.log('finalFilterParams', getAppliedFilterParams());
      console.groupEnd();

      try {
        const { data } = await partnerAPI.adminProduct.adminProductList(
          getAppliedFilterParams()
        );
        productList.value = data as any;
      } catch ({ response }) {
        console.error(response.data);
        alert(response.data.message);
      }
    };

    const startRef = toRef(filterParams, 'start');
    const endRef = toRef(filterParams, 'end');
    const categoryIdRef = toRef(filterParams, 'categoryDepth1Id');
    const salesStatusRef = toRef(filterParams, 'salesStatus');
    const enableBidRef = toRef(filterParams, 'enableBid');
    const showDeletedRef = toRef(filterParams, 'showDeleted');
    const showInvisibledRef = toRef(filterParams, 'showInvisibled');

    watch(
      [isFilterOn, startRef, endRef, categoryIdRef, salesStatusRef, enableBidRef, showDeletedRef, showInvisibledRef ],
      ([isFilterOn]) => {
        // 필터 활성화 상태에서 watch하는 파라미터가 업데이트되면 검색 실행
        if (isFilterOn) {
          executeSearch();
        }
      }
    );

    onMounted(fetchProductLists);

    watch([filterParams, isFilterOn], (newFilter, newIsFilterOn) => {
      console.log('watch filter', newFilter, newIsFilterOn);
      fetchProductLists();
    });

    return {
      filterParams,
      productList,
      toggleApplyFilters,
      handleChangePage,
      handleChangePageSize,
      searchPartner,
      removeSearchPartner,
      executeSearch,
      isFilterOn,
      handleChangeFilterParams,
      handleChangeSort
    };
  },
});
