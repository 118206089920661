
import { defineComponent, PropType, ref } from 'vue';
import { format } from 'date-fns';
import router from '@/router';
import useCategoryOptions from '@/mixins/useCategoryOptions';
import salesAndOrderStatus from '@/constants/salesAndOrderStatus';
import SortingDefaultIcon from '@/components/icons/SortingDefaultIcon.vue';
import SortingDescendingIcon from '@/components/icons/SortingDescendingIcon.vue';
import SortingAscendingIcon from '@/components/icons/SortingAscendingIcon.vue';

export default defineComponent({
  name: 'ListTable',
  components: {
    SortingAscendingIcon,
    SortingDefaultIcon,
    SortingDescendingIcon
  },
  props: {
    products: Object as PropType<any>,
    partnerSearched: {
      type: Boolean,
      default: false
    },
    sortBy : String
  },

  setup(props, { emit }) {
    const { categoryDepth1Obj, categoryDepth2Obj } = useCategoryOptions();
    const { salesStatus } = salesAndOrderStatus();
    const sort = ref(
        {
          lot: 'NONE', // ASC. DESC
          startDate: 'NONE',
          startPrice: 'NONE',
          buynowPrice: 'NONE',
          createdAt: 'NONE',
        }
    )
    if (props.sortBy) {
      const [key, value] = props.sortBy.split(':')
      if (key && value) {
        sort.value[key] = value
      }
    }


    const sortHandler= (name, value) => {
      Object.keys(sort.value).forEach(key => sort.value[key] = 'NONE')
      sort.value[name] = value
      if (value === 'NONE') {
        emit('changeSort', undefined)
        return
      }
      emit('changeSort', `${name}:${value}`)
    }

    const handleClickRow = (item) => {
      router.push({
        path: '/app/product/register',
        query: {
          step: 3,
          productId: item.productId,
        },
      });
    };

    const formatNumber = (number) => {
      if (number === 0) {
        return '0원';
      }
      if (!number) {
        return '-';
      }
      return number.toLocaleString() + '원';
    };

    return {
      format,
      handleClickRow,
      formatNumber,
      salesStatus,
      categoryDepth1Obj,
      categoryDepth2Obj,
      sort,
      sortHandler
    };
  },
});
